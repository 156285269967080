<template>
    <component :is="setContactComponent" />
</template>

<script>

export default {
    components: {
        DefaultContactInfoComponent: () => import('./ContactInfoComponents/DefaultContactInfoComponent.vue'),
        AffaldPlusContactInfoComponent: () => import('./ContactInfoComponents/AffaldPlusContactInfoComponent.vue')
    },
    data() {
        return {
        }
    },
    computed: {
        setContactComponent() {
            return process.env.VUE_APP_TERMS_COMPANY_NAME === 'Affald Plus' ? 'AffaldPlusContactInfoComponent': 'DefaultContactInfoComponent'
        },
    },
    created() {        
    },    
    methods: {
    }
}
</script>
